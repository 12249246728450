<template>
  <b-overlay
    :show="show"
    variant="transparent"
    opacity="0.99"
    blur="5px"
    rounded="sm"
  >
    <template #overlay>
      <div class="text-center">
        <b-icon-controller
          font-scale="3"
          animation="cylon"
        />
        <p id="cancel-label">
          กรุณารอสักครู่...
        </p>
      </div>
    </template>
    <div class="card">
      <div class="card-body">
        <div>
          <h2 class="text-dark font-medium-3">
            <i class="fa fa-search" /> ค้นหา
          </h2>
        </div>
        <div class="row">
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">ประเภทที่จะค้นหา</label>
              <b-form-select
                v-model="search_type"
                :options="search_type_option"
                placeholder="เลือกประเภทที่จะค้นหา"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="username">Keyword</label>
              <input
                id="username"
                v-model="search_val"
                :disabled="!search_type"
                type="text"
                class="form-control"
                placeholder="กรอกข้อมูลที่ต้องการค้นหา"
              >
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateStart">วันที่เริ่มต้น</label>
              <flat-pickr
                v-model="dateStart"
                class="form-control"
                placeholder="เลือกเวลาเริ่มต้น"
              />
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <label for="dateEnd">วันที่สิ้นสุด</label>
              <flat-pickr
                v-model="dateEnd"
                class="form-control"
                placeholder="เลือกเวลาสิ้นสุด"
              />
            </div>
          </div>

          <div class="col-md-3">
            <button
              class="btn btn-gradient-dark"
              @click="getData()"
            >
              <feather-icon icon="SearchIcon" />
              ค้นหา
            </button>
          </div>
        </div>
      </div>
    </div>
    <b-card
      no-body
    >
      <b-card-body>
        <div class="d-flex justify-content-between  flex-wrap">
          <!-- <b-form-group
            class="mr-1 mb-md-0"
          >
            <b-button
              v-b-modal.modal-1
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              block
              @click="addData"
            >
              เติมเครดิต
            </b-button>
          </b-form-group> -->
          <div>
            <b-row>
              <b-col>
                <div class="d-flex justify-content-end">
                  <!-- <b-form-select
                    v-model="search_type"
                    class="d-inline-block mr-1"
                    :options="search_type_option"
                  />

                  <b-form-input
                    v-model="search_val"
                    :disabled="!search_type"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  /> -->

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="success"
                    class="btn-icon"
                    block
                    @click="Export()"
                  >
                    Export
                  </b-button>
                </div>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-card-body>
      <b-table
        small
        striped
        hover
        responsive
        show-empty
        class="position-relative items-center"
        :per-page="perPage"
        :items="items"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
      >
        <template #empty="scope">
          <div class="p-2 text-center">
            <img
              src="/box.png"
              alt="empty"
              height="40px"
            > {{ scope.emptyFiltered ? 'No matching records found' : 'ไม่พบข้อมูล' }}
          </div>
        </template>
        <template #cell(index)="data">
          {{ perPage * (currentPage - 1) + (data.index + 1) }}
        </template>
        <template #cell(Amount)="data">
          <p class="font-weight-bolder text-warning mb-0">
            {{ data.item.Amount ? Commas(Number(data.item.Amount).toFixed(2)) : 0.00 }}
          </p>
        </template>
        <template #cell(AfterBalance)="data">
          <p class="font-weight-bolder text-info mb-0">
            {{ data.item.AfterBalance ? Commas(Number(data.item.AfterBalance).toFixed(2)) : 0.00 }}
          </p>
        </template>

        <template #cell(Status)="data">
          <div class="text-nowrap">
            <b-badge
              v-if="data.item.Status === 'cancel'"
              variant="light-danger"
            >
              {{ data.item.Status }}
            </b-badge>
            <b-badge
              v-if="data.item.Status === 'waiting'"
              variant="light-warning"
            >
              {{ data.item.Status }}
            </b-badge>
            <b-badge
              v-if="data.item.Status === 'success'"
              variant="light-success"
            >
              {{ data.item.Status }}
            </b-badge>
            <b-badge
              v-if="data.item.Status === 'off'"
              variant="light-secondary"
            >
              {{ data.item.Status }}
            </b-badge>
          </div>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <div class="text-nowrap">
            <b-badge
              v-if="data.item.Status === 'waiting' && data.item.ListUser && data.item.ListUser.length > 0"
              variant="light-primary"
              @click="MatchDep(data.item)"
            >
              จับคู่
            </b-badge>
          </div>
        </template>
      </b-table>

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">

        <!-- page length -->
        <b-form-group
          label="Per Page"
          label-cols="8"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
            @change="getData()"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
            @input="getData()"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <b-modal
      ref="modal-match"
      centered
      :title="`จับคู่ ID:${MatchData._id}`"
      size="lg"
      ok-title="ยืนยัน"
      cancel-title="ยกเลิก"
      @ok="StoreCredit"
    >
      <p>จำนวนเงิน : {{ MatchData.Amount }}</p>
      <p>ยูสเซอร์เนม</p>
      <v-select
        v-model="MatchDepSent.predeposit_id"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="username"
        :options="MatchData.ListUser"
        :reduce="ele => ele.predeposit_id"
      />
    </b-modal>
  </b-overlay>
</template>

<script>
// import vSelect from 'vue-select'
// import { ValidationProvider, ValidationObserver } from 'vee-validate'
import moment from 'moment-timezone'
import {
  BBadge,
  // BRow,
  // BCol,
  BOverlay, BIconController, BCard, BTable, BFormGroup, BFormSelect, BPagination,
  BButton,
  BCardBody, VBToggle,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { required, email } from '@validations'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'

export default {
  components: {
    flatPickr,
    BBadge,
    BCard,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    // BInputGroupAppend,
    // BButton,
    BCardBody,
    BOverlay,
    BIconController,
    vSelect,
    BButton,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      search_type_option: [
        { value: 1, text: 'ยูสเซอร์เนม' },
        // { value: 2, text: 'เบอร์โทร' },
      ],
      search_type: null,
      search_val: null,
      required,
      email,
      show: true,
      depositdata: [],
      perPage: 20,
      pageOptions: [10, 15, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      fields: [
        { key: 'index', label: 'no.' },
        { key: 'Status', label: 'สถานะ' },
        { key: 'AgAccno', label: 'เข้าบัญชี' },
        { key: 'TranferType', label: 'ประเภทการโอน' },
        { key: 'Remark', label: 'remark' },
        { key: 'Amount', label: 'จำนวนเงิน' },
        { key: 'AfterBalance', label: 'จำนวนหลังฝาก' },
        { key: 'Branch', label: 'Branch' },
        { key: 'TranferDate', label: 'เวลา Statement' },
        // { key: 'ListUser', label: 'รายการแจ้งฝาก' },
        {
          key: 'created_at',
          label: 'เวลา',
          formatter: value => moment(value)
            .tz('Asia/Seoul')
            .format('DD/MM/YYYY HH:mm:ss'),
        },
        { key: 'actions', label: 'Actions' },

      ],
      items: [],
      UserData: JSON.parse(localStorage.getItem('userData')),
      dateStart: moment().tz('Asia/Seoul').format('YYYY-MM-DD'),
      dateEnd: moment().tz('Asia/Seoul').format('YYYY-MM-DD'),
      MatchData: [],
      MatchDepSent: {
        statement_id: '',
        predeposit_id: '',
      },
    }
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter(f => f.sortable)
        .map(f => ({ text: f.label, value: f.key }))
    },
  },
  created() {
    this.interval = setInterval(() => {
      this.getData()
    }, 30000)
  },
  destroyed() {
    clearInterval(this.interval)
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
    this.getData()
  },
  methods: {
    Export() {
      this.$http
        .get(`WaitDep/export/${this.dateStart}/${this.dateEnd}`, { responseType: 'blob' })
        .then(response => {
          // console.log(response.data)
          const link = document.createElement('a')
          link.href = window.URL.createObjectURL(
            new Blob([response.data]),
          )
          link.setAttribute('download', `รายการ statement วันที่ ${this.dateStart} ถึง ${this.dateEnd}.xlsx`)
          document.body.appendChild(link)
          link.click()
        })
        .catch(error => console.log(error))
    },
    getData() {
      this.show = true
      const params = {
        page: this.currentPage,
        perPage: this.perPage,
        search_type: this.search_type,
        search_val: this.search_val,
        dateStart: this.dateStart,
        dateEnd: this.dateEnd,
      }
      this.$http
        .get('/waitdeposit', { params })
        .then(response => {
          this.show = false
          this.depositdata = response.data.data
          this.onFiltered(response.data)
        })
        .catch(error => console.log(error))
    },
    onFiltered(filteredItems) {
      this.items = filteredItems.data
      this.totalRows = filteredItems.total
    },
    MatchDep(item) {
      this.MatchData = item
      // eslint-disable-next-line no-underscore-dangle
      this.MatchDepSent.statement_id = item._id
      this.MatchDepSent.predeposit_id = item.ListUser[0].predeposit_id
      this.$refs['modal-match'].show()
    },
    StoreCredit() {
      this.show = true
      const obj = {
        statement_id: this.MatchDepSent.statement_id,
        predeposit_id: this.MatchDepSent.predeposit_id,
      }
      this.$http
        .post('/matchdeposit/store', obj)
        .then(() => {
          this.show = false
          this.$refs['modal-match'].hide()
          this.Success('')
        })
        .catch(error => {
          this.show = false
          console.log(error)
          this.SwalError('')
        })
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">เกิดข้อผิดพลาด!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    Commas(x) {
      if (!x) {
        return 0
      } if (x % 1 !== 0) {
        return Number(x).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    },
  },
}
</script>

<style scoped>
table {
  width: 100%;
  color: #fff !important;
}
thead, th, tr {
  color: #fff default;
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";

.form-item-section {
background-color:$product-details-bg;
}
th {
    color: #fff default;
    background-color: #000 !important;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
